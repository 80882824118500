/** @jsx jsx */
import { jsx, Themed, Flex } from 'theme-ui';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default function IndexPage() {
	return (
		<Layout>
			<SEO title="Page not found" />
			<Flex
				sx={{
					position: 'relative',
					height: 'calc(100vh - 4rem)',
					minHeight: 'fit-content',
					maxHeight: '45rem',
					flexDirection: 'column',
					gap: 'xl',
					pt: 'xxxl',
					pb: 'xl',
				}}
			>
				<Themed.h3
					sx={
						{
							// mb: 'xl',
						}
					}
				>
					404. Page not found.
				</Themed.h3>
				<Themed.h6 sx={{ color: 'primary' }}>
					<Link
						to="/"
						sx={{
							variant: 'text.link',
						}}
					>
						Go home
					</Link>
				</Themed.h6>
			</Flex>
		</Layout>
	);
}
